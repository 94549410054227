import {
    useCallback,
    useEffect,
    useRef,
    useState
} from "react";

function useStateCallback(initialState) {
    const [state, setState] = useState(initialState);
    const callbackRef = useRef(null);

    const setStateCallback = useCallback((state, callback) => {
        callbackRef.current = callback;
        setState(state);
    }, []);

    useEffect(() => {
        if(!callbackRef.current) {
            return;
        }
        callbackRef.current(state);
        callbackRef.current = null;
    }, [state]);

    return [state, setStateCallback];
}

export default useStateCallback;
