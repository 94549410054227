import React from "react";
import {
    Trans
} from "react-i18next";

import Loading from "../Loading";

function OrderPendingCard() {
    return (
        <div className="card mb-3">
            <div className="card-body text-center text-md-left">
                <div className="d-flex w-100 align-items-center justify-content-center flex-column flex-md-row">
                    <div className="mb-3 mb-md-0 mr-md-4">
                        <Loading/>
                    </div>
                    <div>
                        <h4 className="card-title mb-1">
                            <Trans i18nKey="orderPendingTitle"/>
                        </h4>
                        <p className="mb-0" style={{ maxWidth: "375px" }}>
                            <Trans i18nKey="orderPendingDescription"/>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(OrderPendingCard);
