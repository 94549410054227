import React from "react";
import {
    Trans
} from "react-i18next";

import OrderCompleteCard from "../../components/order/OrderCompleteCard";
import OrderDetailContents from "../../components/order/OrderDetailContents";
import OrderPendingCard from "../../components/order/OrderPendingCard";

function DefaultOrderSuccessPage({ order }) {
    if(!order) {
        return null;
    }
    return (
        <React.Fragment>
            { order.status?.paid ? (
                <OrderCompleteCard
                    order={ order }
                />
            ) : (
                <OrderPendingCard/>
            )}
            <h3>
                <Trans i18nKey="order"/> #{ order.id }
            </h3>
            <OrderDetailContents
                order={ order }
            />
        </React.Fragment>
    );
}

export default React.memo(DefaultOrderSuccessPage);
