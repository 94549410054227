import React, {
    useEffect,
    useContext,
    useState,
    useCallback
} from "react";
import {
    useHistory,
    withRouter
} from "react-router-dom";
import {
    Alert,
    Button
} from "react-bootstrap";
import i18next from "i18next";
import {
    Trans
} from "react-i18next";

import shopcrateApi from "../../ShopCrateAPI";
import CartContext from "../../context/internal/CartManager";
import PaymentMethodContext from "../../context/internal/PaymentMethodManager";
import Loading from "../../components/Loading";
import DefaultOrderSuccessPage from "./DefaultOrderSuccessPage";
import useStateCallback from "../../hooks/useStateCallback";

const delaySecondsBeforeMaxAttempts = 1;
const delaySecondsAfterMaxAttempts = 15;
const maxOrderStatusAttempts = 20;

function OrderStatusPageContent({ match }) {
    const cart = useContext(CartContext);
    const paymentMethod = useContext(PaymentMethodContext);
    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState(null);
    const [order, setOrder] = useState(null);
    const [error, setError] = useState(null);
    const [retrieveOrderStatusAttempts, setRetrieveOrderStatusAttempts] = useStateCallback(0);
    const [showAnyway, setShowAnyway] = useState(false);

    const getOrder = useCallback((orderToken) => {
        shopcrateApi.post("/getOrder", {
            orderToken
        })
            .then((response) => {
                if(response.data.valid) {
                    setOrder(response.data.order);
                } else {
                    setError("Er ging iets fout bij het ophalen van de data. (" + response.data.error + ")");
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.error(error);
                setError("Er ging iets fout bij het ophalen van de data.");
                setLoading(false);
            });
    }, []);

    const getOrderStatus = useCallback((orderToken, skipReset, updatePaymentPlatformStatus) => {
        if(!skipReset) {
            setLoading(true);
            setError(null);
        }
        shopcrateApi.post("/getOrderStatus", {
            orderToken,
            updatePaymentPlatformStatus: updatePaymentPlatformStatus ? 1 : 0,
        })
            .then((response) => {
                if(response.data.valid) {
                    const status = response.data.status;
                    setStatus(status);
                    if(status === null || status === "open" || status === "pending") {
                        setRetrieveOrderStatusAttempts((prevValue) => prevValue + 1, (currentAttempt) => {
                            const overMaxAttempts = currentAttempt > maxOrderStatusAttempts;
                            if(overMaxAttempts) {
                                console.log("Max attempts reached. Showing order anyway...");
                                cart.refreshCart();
                                paymentMethod.setPaymentMethod(null);
                                setLoading(false);
                                getOrder(orderToken);
                                setShowAnyway(true);
                            }
                            const secondsDelay = overMaxAttempts ? delaySecondsAfterMaxAttempts : delaySecondsBeforeMaxAttempts;
                            const shouldUpdatePaymentPlatformStatus = currentAttempt >= maxOrderStatusAttempts;
                            console.log(`Retrying in ${secondsDelay} seconds... (Attempt ${currentAttempt} / ${maxOrderStatusAttempts})`);
                            setTimeout(() => {
                                getOrderStatus(orderToken, overMaxAttempts, shouldUpdatePaymentPlatformStatus);
                            }, secondsDelay * 1000);
                        });
                    } else if(status === "paid") {
                        console.log("Order has been paid, retrieving order...");
                        cart.refreshCart();
                        paymentMethod.setPaymentMethod(null);
                        setLoading(false);
                        getOrder(orderToken);
                    } else {
                        console.log("Order canceled. Returning to order process...");
                        history.push("/order/overview");
                        setLoading(false);
                    }
                } else {
                    setError(i18next.t("errorGeneral") + " (" + response.data.error + ")");
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.error(error);
                setError(i18next.t("errorGeneral"));
                setLoading(false);
            });
    }, [getOrder, cart.refreshCart, paymentMethod.setPaymentMethod, history, setRetrieveOrderStatusAttempts]);

    useEffect(() => {
        const orderToken = match.params.orderToken;
        getOrderStatus(orderToken, false, false);
    }, [match.params.orderToken, getOrderStatus]);

    return (
        <React.Fragment>
            { error ? (
                <React.Fragment>
                    <Alert variant="danger">{ error }</Alert>
                    <div className="text-center">
                        <Button variant="success" size="lg" onClick={ getOrderStatus }>
                            <Trans i18nKey="tryAgain"/>
                        </Button>
                    </div>
                </React.Fragment>
            ) : loading || !order ? (
                <div className="card mb-3">
                    <div className="card-body text-center">
                        <h4><Trans i18nKey="orderStatusLoading"/></h4>
                        <Loading/>
                    </div>
                </div>
            ) : status === "paid" || showAnyway ? (
                <DefaultOrderSuccessPage order={ order }/>
            ) : (
                <div className="card mb-3">
                    <div className="card-body text-center">
                        <h4>
                            <Trans i18nKey="retrieveOrderStatusTitle"/>
                        </h4>
                        <Button variant="success" size="lg" onClick={ getOrderStatus }>
                            <Trans i18nKey="refresh"/>
                        </Button>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}

export default React.memo(withRouter(OrderStatusPageContent));
